<template>
  <div class="box" v-if="!change">
    <div class="item">
      <div class="left">头像：</div>
      <div class="right">
        <img :src="userInfo.avatar" v-if="userInfo.avatar" class="avatar" />
        <img v-else src="../../../../assets/images/basicLayout/avatar.jpg" class="avatar" />
          <el-upload
            class="avatar-uploader"
            :before-remove="handleRemove"
            :on-exceed="handleExceed"
            :data="{
              directory: 'SHOP_INFO'
            }"
            action="https://api.etouch.top/admin/common.UploadFile/uploadOssImage"
            :with-credentials="true"
            :show-file-list="false"
            :on-error="handleAvatarError"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <el-button type="primary" size="mini">修改头像</el-button>
          </el-upload>
      </div>
    </div>
    <div class="item">
      <div class="left">姓名：</div>
      <div class="right">
        {{userInfo.realname || '--'}}
      </div>
    </div>
    <div class="item">
      <div class="left">手机号：</div>
      <div class="right">
        {{userInfo.username || '--'}}
      </div>
    </div>
    <div class="item">
      <div class="left">入职时间：</div>
      <div class="right">
        {{userInfo.hiredate || '--'}}
      </div>
    </div>
    <div class="item">
      <div class="left">密码：</div>
      <div class="right">
        已设置
        <el-button type="text" style="margin-left: 20px;" @click="change = true">修改密码</el-button>
      </div>
    </div>
    <!-- 1未绑定 2未授权企微 3已绑定 -->
    <div class="item" v-if="temp.bind_type==1">
      <div class="left">企业微信：</div>
      <div class="right">
        未绑定
        <el-button type="text" style="margin-left: 20px;" @click="bindingQiweiAccount">绑定企微</el-button>
      </div>
    </div>
    <div class="item" v-if="temp.bind_type==3">
      <div class="left">企业微信：</div>
      <div class="right">
        已绑定
        <el-button type="text" style="margin-left: 20px;" @click="unbindEwechat">解绑企微</el-button>
      </div>
    </div>
    <div class="item">
      <div class="left"></div>
      <div class="right">
        <el-button type="primary" @click="onSubmit" :loading="loading">保存</el-button>
      </div>
    </div>
    <el-dialog :visible.sync="codeVisible" title="绑定个人企业微信" width="40%" :before-close="handleCloseCode">
        <div ref="qrCodeUrl" class="qrcode" id="qrcode"></div>
        <div class="Code_text">请用企业微信扫一扫进行授权</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCloseCode">取 消</el-button>
          <el-button type="primary" @click="handleCode">确 定</el-button>
        </span>
    </el-dialog>
  </div>
  <ChangePassword v-else :userInfo="userInfo" @onHide="change = false" />
</template>

<script>
import storage from 'good-storage';
import ChangePassword from './ChangePassword.vue';
import { updateAvatarReq } from '@/api/system/setting';
import { bindPersonEwechatUrl} from '@/api/authority/menage';
import QRCode from 'qrcodejs2';
export default {
  data() {
    return {
      userInfo: {},
      loading: false,
      change: false,
      codeVisible: false,//二维码的显示隐藏
      temp:{

      }
    };
  },
  components: {
    ChangePassword,
  },
  created() {
    this.userInfo = storage.get('userInfo');
    console.log(this.userInfo)
    this.userInfo.phone = this.userInfo.username.slice(0,3) + '****' + this.userInfo.username.slice(this.userInfo.username.length - 4);
    this.bindPersonEwechatUrl();
  },
  methods: {
    onSubmit() {
      if (!this.userInfo.avatar) {
        this.$message.warning('请上传头像');
        return;
      }
      updateAvatarReq(this.userInfo.avatar)
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('保存成功');
            storage.set('userInfo', this.userInfo);
          }
        });
    },//获取绑定企微个人信息参数
    bindPersonEwechatUrl(){
      bindPersonEwechatUrl().then((res) => {
        console.log(res)
        if (res.code === 1) {
            this.temp=res.data
        }
      })
    },
    //生成绑定企微个人信息二维码
    bindingQiweiAccount(row){
      let that=this;
      this.codeVisible=true;
      const codeHtml = document.getElementById("qrcode");
      console.log(codeHtml)
      if(codeHtml!=null){
         codeHtml.innerHTML = "";
      }

      let obg={
        buss_hid:this.temp.res_data.buss_hid,
        auth_state:this.temp.res_data.auth_state,
      }
      let data=JSON.stringify(obg);
      setTimeout(function(){

        let qrcode = new QRCode(that.$refs.qrCodeUrl, {
          text: data, // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff' ,
          correctLevel:  QRCode.CorrectLevel.H
        })

      }, 500)
     },//解绑企微
     unbindEwechat(){
      this.$confirm('确认解除企业微信绑定？').then(_ => {
        unbindEwechat({store_user_id:[row.store_user_id]}).then((res) => {
          console.log(res);
          if(res.code==1){
            this.$message({
              message: res.msg,
              type: 'success'
            });
            this.loading2 = false;
            this.getList();
          }

        })
      })
     },
    //关闭二维码
    handleCloseCode(){
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
      this.codeVisible=false;
      this.bindPersonEwechatUrl();
    },//确认关闭
    handleCode(){
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
      this.codeVisible=false;
      this.bindPersonEwechatUrl();
    },
    // 上传图片
    handleRemove() {
      const userInfo = { ...this.userInfo };
      userInfo.avatar = '';
      this.userInfo = userInfo;
    },
    handleExceed() {
      // this.$message.error('最多上传1张头像');
      console.log('最多上传1张头像');
    },
    // 上传失败
    handleAvatarError() {
      this.$message.error('上传失败');
    },
    // 上传成功
    handleAvatarSuccess(res, file) {
      // this.userInfo.avatar = URL.createObjectURL(file.raw);
      const userInfo = { ...this.userInfo };
      userInfo.avatar = res.data;
      this.userInfo = userInfo;
    },
    // 上传之前
    beforeAvatarUpload(file) {
      const isImage = file.type.indexOf('image') > -1;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error('只能上传图片格式!');
        return isImage;
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
        return isLt2M;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: white;
  font-size: 14px;
  padding: 30px 0;
  .item {
    display: flex;
    line-height: 60px;
    .left {
      width: 150px;
      text-align: right;
      margin-right: 20px;
    }
    .right {
      display: flex;
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border: 1px solid #eee;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
  }
  .qrcode{
    display: flex;
    align-content: center;
    justify-content: center;
    img {
        width: 132px !important;
        height: 132px;
        background-color: #fff; //设置白色背景色
        padding: 6px; // 利用padding的特性，挤出白边
        box-sizing: border-box;
    }
}
.Code_text{
  text-align: center;
  margin-top: 40px;
  font-size: 16px;
}
}
</style>

import { post } from '@/utils/request';

const loginReq = ({
  username, password, type, geetest_challenge, geetest_validate, geetest_seccode, phone, code,
}) => post({
  url: '/store/authority.user/login',
  data: {
    geetest_challenge,
    geetest_validate,
    geetest_seccode,
    username,
    password,
    type,
    phone,
    code,
  },
});

const verCodeReq = () => post({
  url: '/store/authority.user/verCode',
});

const sendCodeReq = ({
  phone, geetest_challenge, geetest_validate, geetest_seccode, type,
}) => post({
  url: '/store/common.sms/sendCode',
  data: {
    type,
    phone,
    geetest_challenge,
    geetest_validate,
    geetest_seccode,
  },
});

const reSetpassReq = ({
  username, code, password, password1,
}) => post({
  url: '/store/authority.user/resetPass',
  data: {
    username,
    code,
    password,
    password1,
  },
});

// 选择门店
const selectShopReq = ({ hierarchy_id, store_user_id, authorization }) => post({
  url: '/store/authority.user/selectShop',
  headers: {
    authorization,
  },
  data: {
    hierarchy_id,
    store_user_id,
  },
});

// 获取最新版本
const getVersionNewReq = (params) => post({
  url: '/store/app.Home/getVersionNew',
  data: {
    ...params,
  },
});

// 获取二维码以及状态
const getLoginCode = (params) => post({
  url: '/store/authority.User/SweepTheLoginCode',
  data: params
})

export {
  loginReq,
  verCodeReq,
  sendCodeReq,
  reSetpassReq,
  selectShopReq,
  getVersionNewReq,
  getLoginCode
};

<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="修改密码">
      </el-page-header>
    </div>
    <div class="content">
      <div class="top">
        <div class="item" @click="handleStep(1)">
          <div class="num" :class="step >= 1? 'active': ''">1</div>
          <span :class="step >= 1? 'activetxt': ''">验证身份</span>
        </div>
        <div class="xian" :class="step >= 2? 'active': ''"></div>
        <div class="item" @click="handleStep(3)">
          <div class="num" :class="step >= 3? 'active': ''">2</div>
          <span :class="step >= 3? 'activetxt': ''">输入密码</span>
        </div>
        <div class="xian" style="left: 50%;" :class="step >= 4? 'active': ''"></div>
        <div class="item" @click="handleStep(5)">
          <div class="num" :class="step >= 5? 'active': ''">3</div>
          <span :class="step >= 5? 'activetxt': ''">完成</span>
        </div>
      </div>
      <div class="step step1" v-show="step === 1">
        <div style="margin-bottom: 40px;">
          已绑定的手机号
          <span style="margin-left: 30px;">{{userInfo.phone}}</span>
        </div>
        <el-form>
          <el-form-item class="flex">
            <el-input v-model="form.code" placeholder="验证码" class="get-phone"></el-input>
              <el-button type="primary" class="get-code" @click="getCode('sms_reset_pass')">{{time? time + 's': '获取验证码'}}</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="btn" @click="handleNext" :disabled="!checkGt2">下一步</el-button>
            </el-form-item>
        </el-form>
      </div>
      <div class="step step2" v-show="step === 3">
        <el-form :model="form" label-width="80px">
          <el-form-item label="新密码" required>
            <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码" required>
            <el-input v-model="form.password1" placeholder="请再此输入密码" show-password></el-input>
          </el-form-item>
           <el-form-item>
            <el-button type="primary" style="width: 100%;" @click="onSubmit" :loading="loading">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="step step5" v-show="step === 5">
        <i class="el-icon-success" style="font-size: 50px; color: #67C23A; margin-bottom: 20px;"></i>
        <div>修改密码成功</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  verCodeReq,
  sendCodeReq,
  reSetpassReq,
} from '@/api/newLogin';

export default {
  data() {
    return {
      step: 1,
      form: {
        username: '',
        code: '',
        password: '',
        password1: '',
      },
      time: 0,
      captchaObj2: null,
      checkGt2: false,
      loading: false,
    };
  },
  props: {
    userInfo: Object,
  },
  created() {
    this.gtInit2();
  },
  methods: {
    onSubmit() {
      this.loading = true;
      reSetpassReq({ ...this.form, username: this.userInfo.username })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.step = 5;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleNext() {
      if (!this.form.code) {
        this.$message.warning('请填写验证码');
        return;
      }
      this.step = 3;
    },
    handleStep(step) {
      if (step <= this.step) {
        this.step = step;
      }
    },
    goBack() {
      this.$emit('onHide');
    },
    getCode(smsType) {
      this.smsType = smsType;
      if (this.time) {
        return;
      }
      const phone = this.userInfo.username;
      if (!phone) {
        this.$message.error('请输入11位手机号');
        return;
      }
      if (phone.length < 11) {
        this.$message.error('请输入11位手机号');
        return;
      }
      this.captchaObj2.verify();
    },
    gtInit2() {
      verCodeReq()
        .then((data) => {
          initGeetest({
            gt: data.gt,
            challenge: data.challenge,
            offline: !data.success,
            new_captcha: data.new_captcha,
            width: '100%',
            product: 'bind',
          }, (captchaObj) => {
            this.captchaObj2 = captchaObj;
            captchaObj.onReady(() => {
            })
              .onSuccess(() => {
                const result = this.captchaObj2.getValidate();
                this.checkGt2 = true;
                const phone = this.userInfo.username;
                const reqObj = { ...result, phone, type: this.smsType };
                sendCodeReq(reqObj)
                  .then((res) => {
                    if (res.code === 1) {
                      this.time = 60;
                      const timer = setInterval(() => {
                        this.time -= 1;
                        if (this.time === 0) {
                          clearInterval(timer);
                        }
                      }, 1000);
                    }
                  });
              })
              .onError(() => {
                console.log('失败');
              });
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.step5 {
  text-align: center;
}
.btn {
  width: 300px;
  margin-top: 20px;
}
.flex {
  /deep/ .el-form-item__content {
    display: flex;
  }
}
.get-phone {
  /deep/ .el-input__inner {
    border-radius: 4px  0 0 4px !important;
  }
}
.get-code {
  border-radius: 0px;
}
.step {
  padding: 100px 0;
  width: 25%;
  margin: 0 auto;
}
.active {
  background: #409EFF !important;
}
.content {
  padding: 50px 150px;
  margin-top: 10px;
  background: white;
  box-sizing: border-box;
  .top {
    display: flex;
    justify-content: space-between;
    position: relative;
    .xian {
      position: absolute;
      width: 50%;
      height: 2px;
      background: #ccc;
      top: 20px;
      left: 25px;
    }
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #ccc;
      z-index: 9;
      cursor: pointer;
      .num {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: white;
        background: #ccc;
        border-radius: 50%;
        margin-bottom: 10px;
      }
      .activetxt {
        color: #409EFF;
      }
    }
  }
}
</style>

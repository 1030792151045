import { post } from '@/utils/request';

// 换头像
const updateAvatarReq = (avatar_url) => post({
  url: '/store/authority.user/update_avatar',
  data: {
    avatar_url,
  },
});

export {
  updateAvatarReq,
};
